import React from 'react'
import "./VideoBox.css"


const VideoBox = (props) => {
  return (
    <div className='video'>
        <div className='box'>
            <div className='image2'>
                <video src={props.video} autoPlay loop muted/>
            </div>
            <div className='text'>
                <h5>{props.heading}</h5>
                <p>{props.content}</p>
            </div>
        </div>
       
       <div className='big2'>
        <div className='m-text-m'>
            <h1 className='one'>{props.top}</h1>
        </div>
        <div className='m-text-two-m'>
            <h1 className='one'>{props.btm}</h1>
        </div>
        </div>
        <div className='more-m'>
            <button>read more <i class="fa-solid fa-chevron-right"></i></button>
        </div>
    </div>
  )
}

export default VideoBox