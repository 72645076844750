import React from 'react'
import logo from "../../assests/image/logo.png";
import "./Nav.css"

const Nav = () => {
  return (
    <div className='nav'>
        <div className='logo'>
            <img src={logo} alt="img"/>
        </div>
        <div className='menu'>
            <i className='fas fa-bars'/>
        </div>
    </div>
  )
}

export default Nav