import React from 'react'
import "./VideoBox2.css"


const VideoBox2 = (props) => {
    return (
      <div className='video'>
          <div className='box'>
              <div className='text'>
                  <h5>{props.heading}</h5>
                  <p>{props.content}</p>
              </div>
              <div className='image'>
                  <video src={props.video} autoPlay loop muted/>
              </div>
          </div>
          <div className='big'>
          <div className='m-text'>
              <h1 className={props.top_clr}>{props.top}</h1>
          </div>
          <div className='m-text-two'>
              <h1 className={props.btm_clr}>{props.btm}</h1>
          </div>
          </div>
          <div className='more'>
              <button className={props.btn_clr}>read more <i class="fa-solid fa-chevron-right"></i></button>
          </div>
      </div>
    )
  }

export default VideoBox2