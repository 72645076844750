import React from 'react'
import Nav from '../Nav/Nav'
import VideoBox from '../Video-box/VideoBox'
import VideoBox2 from '../Video-box/VideoBox2'
import watch from "../../assests/video/watch.mp4";
import train8 from "../../assests/video/train8.mp4";
import man from "../../assests/video/man.mp4";
import bie from "../../assests/video/bie.mp4";
import food from "../../assests/video/food.mp4";
import yoga from "../../assests/video/yoga.mp4";
import phone from "../../assests/video/phone.mp4";

const Home = () => {
  return (
    <div className='home'>
        <div classname="navigation">
            <Nav/>
        </div>
        <div className='content'>
            <VideoBox2
            video={phone}
            heading="GIRL DOING YOGA ON THE SHORE OF THE OCEAN ON THE BEACH"
            content="There are many variations of passages of have suffered
             alteration in some, by injected humour, or randomised ."
            top="RELAX"
            btm="YOGA"
            top_clr="green"
            btm_clr="green"
            btn_clr="green"
            />
            <VideoBox
            video={watch}
            heading="APPLE WATCH UI KIT FOR FREE DOWNLOAD"
            content="There are many variations of passages of have suffered
             alteration in some, by injected humour, or randomised ."
            top="TREND"
            btm="BRAND"
            color1=""
            color2=""
            color3=""
            />
            <VideoBox2
            video={train8}
            heading="APPLE WATCH UI KIT FOR FREE DOWNLOAD"
            content="There are many variations of passages of have suffered
             alteration in some, by injected humour, or randomised ."
            top="CROSSFIT"
            btm="TRAIN"
            top_clr="brown"
            btm_clr="brown"
            btn_clr="brown"
            />
            <VideoBox
            video={man}
            heading="APPLE WATCH UI KIT FOR FREE DOWNLOAD"
            content="There are many variations of passages of have suffered
             alteration in some, by injected humour, or randomised ."
            top="DIGITAL"
            btm="AGENCY"
            color1=""
            color2=""
            color3=""
            />
              <VideoBox2
            video={bie}
            heading="GIRL DOING YOGA ON THE SHORE OF THE OCEAN ON THE BEACH"
            content="There are many variations of passages of have suffered
             alteration in some, by injected humour, or randomised ."
            top="RELAX"
            btm="YOGA"
            color1=""
            color2=""
            color3=""
            />
            <VideoBox
            video={yoga}
            heading="APPLE WATCH UI KIT FOR FREE DOWNLOAD"
            content="There are many variations of passages of have suffered
             alteration in some, by injected humour, or randomised ."
            top="APPLE"
            btm="UI KITS"
            color1=""
            color2=""
            color3=""
            />
              <VideoBox2
            video={food}
            heading="GIRL DOING YOGA ON THE SHORE OF THE OCEAN ON THE BEACH"
            content="There are many variations of passages of have suffered
             alteration in some, by injected humour, or randomised ."
            top="RELAX"
            btm="YOGA"
            color1=""
            color2=""
            color3=""
            />
             
        </div>
    </div>
  )
}

export default Home